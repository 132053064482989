
import { defineComponent, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import ApiService from '@/core/services/ApiService';
import { ScrollComponent } from '@/assets/ts/components/_ScrollComponent';
import { MenuComponent } from '@/assets/ts/components/MenuComponent';
import { version } from '@/core/helpers/documentation';
import { asideMenuIcons } from '@/core/helpers/config';
import { VueCookieNext } from 'vue-cookie-next';
import { apiEndpoint } from '@/mixin/apiMixin.js';
//import MainMenuConfig from "./Menu";

export default defineComponent({
  mixins: [apiEndpoint],
  name: 'kt-menu',
  data() {
    return {
      loading: true,
      entity_type_name: '',
      MainMenuConfig: [
        {
          heading: 'menu',
          route: '/company',
          pages: [
            {
              sectionTitle: 'Company',
              route: '/company/trainning-partner',
              svgIcon: 'media/icons/duotune/general/gen025.svg',
              fontIcon: 'bi-app-indicator',
              sub: [
                {
                  heading: 'Company List',
                  route: '/company/company-list',
                },
                {
                  heading: 'Add Company',
                  route: '/company/new-company',
                },
              ],
            },
          ],
        },
      ],
    };
  },
  async created() {
    await this.menucheck();
  },
  methods: {
    async menucheck() {
      let entity_type_id = VueCookieNext.getCookie('_seip_entity_type');
      console.log(entity_type_id);
      if (entity_type_id != 1004) {
        this.MainMenuConfig = [
          {
            heading: 'menu',
            route: '/company',
            pages: [
              {
                sectionTitle: 'Company',
                route: '/company/trainning-partner',
                svgIcon: 'media/icons/duotune/general/gen025.svg',
                fontIcon: 'bi-app-indicator',
                sub: [
                  {
                    heading: 'Company List',
                    route: '/company/company-list',
                  },
                   {
                    heading: 'Add Company',
                    route: '/company/new-company',
                  },
                ],
              },
            ],
          },
        ];
      }
    },
  },

  components: {},
  setup() {
    const { t, te } = useI18n();
    const route = useRoute();
    const scrollElRef = ref<null | HTMLElement>(null);

    onMounted(() => {
      ScrollComponent.reinitialization();
      MenuComponent.reinitialization();
      if (scrollElRef.value) {
        scrollElRef.value.scrollTop = 0;
      }
    });

    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    const hasActiveChildren = (match) => {
      return route.path.indexOf(match) !== -1;
    };

    return {
      hasActiveChildren,
      asideMenuIcons,
      version,
      translate,
    };
  },
});
